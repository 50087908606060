import React, { useRef } from "react"
import styled from 'styled-components'
import { Field } from "../../styles/"
import { FontSize } from "../../variables"

const Select = styled.select`
	${FontSize.sm}
`

export const SelectField = ({ label, name, options, required, short, value, setValue }) => {

	const selectRef = useRef();

	function handleChange(e) {
		e.persist();
		setValue(currentValues => ({
			...currentValues,
			[name]: selectRef.current.value
		}))
	}

	return (
		<Field short={ short }>
			<label>
				<p>{label}{required && '*'}</p>
				<Select
					name={ name } 
					defaultValue={ value }
					ref={selectRef}
					onChange={ handleChange }
					required={ required }>
					<option value="" disabled defaultValue>Select</option>
                    {options.map((option, i) => {
						return (
							<option key={i} value={ option.value }>{ option.label }</option>
						)
					})}
                </Select>
			</label>
		</Field>
	)
}
