const size = {
	sm: '640px',
	md: '768px',
	lg: '1024px',
	xl: '1280px',
	xxl: '1536px',
}
export const BreakPoint = {
	sm: `(min-width: ${size.sm})`,
	md: `(min-width: ${size.md})`,
	lg: `(min-width: ${size.lg})`,
	xl: `(min-width: ${size.xl})`,
	xxl: `(min-width: ${size.xxl})`,
	ie: `@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)`
};