import styled from 'styled-components'

export const ButtonWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: ${props => props.left ? 'flex-start' : 'center'};
	margin-top: 20px;
	margin-bottom: -10px;
	> * {
		margin-right: 10px;
		margin-bottom: 10px;
		&:last-child {
			margin-right: 0px;
		}
	}
	&:first-child {
		margin-top: 0;
	}
`
